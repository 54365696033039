<template>
    <div class="constructionlist-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="添加单位" to="/client/unit/add" />
                <van-tab replace title="施工单位" to="/client/unit/list/construction" />
                <van-tab replace title="巡护单位" to="/client/unit/list/patrol" />
            </van-tabs>
        </div>
        <div class="construction-list-body">
            <div v-for="(item, index) in unitList" class="list-item">
                <van-swipe-cell>
                    <div class="information-group">
                        <p>{{ item.name }}</p>
                        <p v-if="item.status === 0" class="p2">已禁止</p>
                        <p v-if="item.status === 1" class="p1">启用</p>
                    </div>
                    <template #right>
                        <div class="slide-button-group">
                            <van-button
                                :block="true"
                                class="slide-button"
                                color="#DF5F0B"
                                square
                                @click="toDetail(item.id)"
                            >
                                编辑
                            </van-button>
                            <!--<van-button :block="true" class="slide-button" color="#D43030" square>删除</van-button>-->
                        </div>
                    </template>
                </van-swipe-cell>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_UNIT_LIST } from "@/api/goods";
import usePublic from "@/hooks/usePublic";

let { $router } = usePublic();

onMounted(() => {
    getUnitList();
});

let tabActive = ref(2);

// 获取列表
let unitList = ref([]);

let getUnitList = () => {
    GET_UNIT_LIST({ type: 1, limit: 10000 }).then((res) => {
        unitList.value = res.data.data.list;
        console.log(res.data.data);
    });
};

let toDetail = (id) => {
    $router.push({
        name: "UnitAdd",
        query: {
            id
        }
    });
};
</script>

<style lang="scss" scoped>
.constructionlist-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .construction-list-body {
        margin-top: 10px;
        background: #fff;

        .list-item {
            font-size: 14px;
            border-bottom: dashed 1px #f0f0f0;

            .information-group {
                min-height: 60px;
                padding: 0 15px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .p1 {
                    color: #43cf7c;
                }

                .p2 {
                    color: #999;
                }
            }

            .slide-button-group {
                display: flex;
                flex-direction: row;

                .slide-button {
                    height: 60px;
                }
            }
        }
    }
}
</style>
